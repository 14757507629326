import React, { useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import logo from '../../assets/Logo.png';
import commonStyles from '../../commonUtils/commonStyles';

function MaintenanceScreen() {
  const [timerCount, setTimerCount] = useState(10);
  let timerExt = 10;

  useEffect(() => {
    startTimer();
  }, []);

  const startTimer = () => {
    const redirectTimer = setInterval(() => {
      if (timerExt <= 1) {
        clearInterval(redirectTimer);
        window.location.replace('https://lk.etrann.com');
      }
      timerExt = timerExt - 1;
      setTimerCount(timerExt);
    }, 1000);
  };

  const redirect = (event) => {
    event.preventDefault();
    window.location.replace('https://lk.etrann.com');
  };

  return (
    <Grid container style={getStyles().root}>
      <Grid container justify="center" alignItems="center" style={getStyles().grid}>
        <Grid container>
          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Paper elevation={3} style={getStyles().paperMain}>
              <Grid container>
                <Grid item style={getStyles().header}>
                  <CardMedia component="img" image={logo} style={getStyles().logoImage} />
                  <Grid item style={getStyles().header}>
                    <Typography style={commonStyles.text.robotoRegular18}>
                      Личный кабинет переехал на новый адрес:
                      <br />
                      <Link href="https://lk.etrann.com" onClick={redirect}>
                        lk.etrann.com
                      </Link>
                      <br />
                      Вы будете автоматически перенаправлены на новый адрес через {timerCount} секунд.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        </Grid>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    root: {
      background: commonStyles.color.background,
      width: '100%',
      height: '100vh',
    },
    grid: {
      //border: '1px solid',
      //borderColor: 'red',
      height: '100%',
    },
    logoImage: {
      width: 'auto',
    },
    maintenanceImage: {
      maxWidth: 500,
      marginTop: '30px',
      marginBottom: '30px',
    },
    paperMain: {
      margin: 'auto',
      maxWidth: 500,
      minWidth: 500,
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: '30px 45px',
    },
  };
  return styles;
};

export default MaintenanceScreen;
