import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';

//common styles
import commonStyles from './commonUtils/commonStyles';

import MaintenanceScreen from './screens/MaintenanceScreen/MaintenanceScreen';

const App = () => {
  require('dotenv').config();
  document.body.style.background = commonStyles.color.background;

  return <MaintenanceScreen />;
};

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
